import React, {useEffect, useState} from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import browserHistory from "../../helper/browserHistory";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import DatePicker from "react-datepicker";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {inject, observer} from "mobx-react";
import {getTimespanToShow} from "../../helper/util";
import BlogArticleCardComponent from "../blog/BlogArticleCardComponent";
import config from "../../config/main.config";
import moment from "moment/moment";


function showArticles(filteredProjects, projectsAmount) {
    return filteredProjects.map((project, index) => {
        if (index < projectsAmount) {
            return (<>
                    {index % 3 === 0 ?
                        <Grid.Column width={2} only={"computer"} key={project.id + "padding-col-start"}/> : null}
                    {(index % 3 === 0 && index !== 0) ?
                        <Grid.Column width={2} only={"computer"} key={project.id + "padding-col-end"}/> : null}
                    <Grid.Column computer={4} tablet={8} mobile={16} stretched key={project.id}>
                        <BlogArticleCardComponent
                            titleText={project.header}
                            teaserText={project.description}
                            imageLink={project.thumbnail}
                            buttonLink={project.id}
                            dateText={project.createdAt}
                            tags={project.tags}
                        />
                    </Grid.Column>

                </>
            )
        }
        return null
    })
}

function filterProject(project, searchString, timespan) {
    let checkTime = true;
    if (timespan.startDate !== null || timespan.endDate !== null) {
        const dateToCheck = moment(project.date, "DD.MM.YYYY").toDate();
        checkTime = moment(dateToCheck).isSameOrBefore(timespan.endDate) && moment(dateToCheck).isSameOrAfter(timespan.startDate)
    }
    return (project.header.toLowerCase().includes(searchString.toLowerCase()) || project.tags.toLowerCase().includes(searchString.toLowerCase())) && checkTime
}


const ProjectPage = inject("stores")(observer((props) => {
        const ADDER = 3;
        const namespace = props.route.namespace;
        const {textStore} = props;
        const [shownProjects, setShownProjects] = useState(3);
        const [searchString, setSearchString] = useState("");
        const [timespan, setTimespan] = useState({startDate: null, endDate: null})
        const [allOwnProjects, setAllOwnProjects] = useState([])

        useEffect(() => {
            const fetchData = async () => {
                const projectsWithSubCategory = await (await fetch(config.BASE_URL + "blog/own-projects")).json()
                setAllOwnProjects(projectsWithSubCategory)
            }
            fetchData()
        }, []);


        return (
            <Grid>
                <TitleImageComponent
                    textStore={textStore}
                    namespace={'unsere_schwerpunkte'}
                    imageLinkId={"unsere_schwerpunkte-title-image-id"}
                    headerId={"unsere_schwerpunkte-title-header-id"}
                    subheaderId={"unsere_schwerpunkte-subtitle-header-id"}
                />
                <Grid.Row>
                    <Grid.Column width={2}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => browserHistory.push("/")}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section
                                className={"active-breadcrumb nav-item-green"}>UNSERE SCHWERPUNKTE</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={14} tablet={14} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={"https://www.buergerstiftung-kreis-rv.de/images/seperator.png"}
                               size={"tiny"}
                        />
                        <h2>
                            <EditSimpleValueComponent textStore={textStore}
                                                      id={"unsere_schwerpunkte-sector-2-title-id"}
                                                      namespace={namespace}/></h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={14} mobile={16}>
                        <p className={"text-block"}>

                            <EditSimpleValueComponent textStore={textStore}
                                                      id={"unsere_schwerpunkte-sector-1-text-id"}
                                                      namespace={namespace}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column computer={5} tablet={8} mobile={16}>
                                    <Input label={'Suchen'} iconPosition={'right'} icon={'search'}
                                           value={searchString} onChange={e => setSearchString(e.target.value)}/>
                                </Grid.Column>
                                <Grid.Column computer={5} tablet={8} mobile={16}>
                                    <Dropdown
                                        style={{marginBottom: "20px"}}
                                        text={timespan.startDate == null || timespan.endDate == null ? "ZEITRAUM" : getTimespanToShow(timespan)}
                                        className={timespan.startDate == null || timespan.endDate == null ?
                                            "inactive-cat-button timespan-dropdown"
                                            :
                                            "call-to-action-button-blue timespan-dropdown"}
                                        simple>
                                        <Dropdown.Menu>
                                            <Segment>
                                                <Form>
                                                    <Form.Group widths={"equal"}>
                                                        <Form.Field>
                                                            <label className={"text-block"}>START DATUM</label>
                                                            <DatePicker
                                                                selected={timespan.startDate}
                                                                onChange={date => setTimespan({
                                                                    ...timespan,
                                                                    startDate: date
                                                                })}
                                                                selectsStart
                                                                startDate={timespan.startDate}
                                                                endDate={timespan.endDate}
                                                                dateFormat="dd.MM.yyy"/>
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <label className={"text-block"}>END DATUM</label>
                                                            <DatePicker
                                                                selected={timespan.endDate}
                                                                onChange={date => setTimespan({...timespan, endDate: date})}
                                                                selectsEnd
                                                                endDate={timespan.endDate}
                                                                minDate={timespan.startDate}
                                                                dateFormat="dd.MM.yyy"/>
                                                        </Form.Field>
                                                    </Form.Group>
                                                    <Form.Field>
                                                        <Button icon labelPosition='right'
                                                                inverted color='green'
                                                                onClick={() => setTimespan({
                                                                    startDate: null,
                                                                    endDate: null
                                                                })}>
                                                            Zurücksetzen
                                                            <Icon name='undo'/>
                                                        </Button>
                                                    </Form.Field>
                                                </Form>
                                            </Segment>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {showArticles(allOwnProjects.filter((project) => filterProject(project, searchString, timespan)), shownProjects)}
                </Grid.Row>
                <Grid.Row centered>
                    {shownProjects < allOwnProjects.length ?
                        <Grid.Column computer={5} tablet={8} mobile={10}>
                            <Button circular fluid className={"call-to-action-button"}
                                    onClick={() => setShownProjects(shownProjects + ADDER)}>
                                MEHR PROJEKTE
                            </Button>
                        </Grid.Column>
                        :
                        null
                    }
                </Grid.Row>
            </Grid>
        )
    }
))

export default ProjectPage